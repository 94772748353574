import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

constructor(private _http: HttpClient) { }

protected http = this._http;

}
