import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { components } from '../interfaces/swagger-types';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  loginValidation(email: string, password: string) {
    return this.http.post('authentication/login-validation', { email, password }, { responseType: 'blob' });
  }

  login2fa(email: string, password: string, verificationCode: string) {
    type LoginResponse = components['schemas']['JWTViewModel'];
    return this.http.post<LoginResponse>('authentication/login-2fa', { email, password, verificationCode });
  }
}
